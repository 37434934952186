<template>
  <div class="principal justify-content-center">
    <div id="block_container" class="d-flex justify-content-center contenedor">
      <div class="contenedor">
        <div class="card text-center  ">
          <div class="card-body text-secondary login-principal">
            <img
              class="card-img-top"
              src="../images/login.png"
              alt="Card image cap"
            />

            <div v-if="opcion == 2">
              <p>
                Contraseña cambiada correctamente
              </p>
            </div>
            <div v-if="comprobado == true && opcion == 1">
              <p>
                Escriba la nueva contraseña:
              </p>
              <div class="form-group mb-3">
                <b-col style="padding-left: 10rem;padding-right: 10rem;">
                  <label for="password" class="form-label">Contraseña</label>
                  <input
                    v-validate="'required|min:6'"
                    name="password"
                    type="password"
                    class="form-control texfield-formulario"
                    ref="password"
                    v-model="password"
                  />
                </b-col>
              </div>
              <div class="form-group mb-3">
                <b-col style="padding-left: 10rem;padding-right: 10rem;">
                  <label for="password2" class="form-label"
                    >Confirmar contraseña</label
                  >
                  <input
                    v-validate="'required|confirmed:password'"
                    name="password_confirmation"
                    type="password"
                    class="form-control  texfield-formulario"
                    data-vv-as="password"
                    v-model="password2"
                  />
                </b-col>
              </div>

              <div class="alert alert-danger" v-show="errors.any()">
                <div v-if="errors.has('password')">
                  La contraseña es requerida y debe tener un mínimo de 6
                  caracteres
                </div>
                <div v-if="errors.has('password_confirmation')">
                  Las contraseñas no coinciden
                </div>
              </div>

              <button
                variant="outline-success"
                :disabled="
                  !(
                    password.length > 0 &&
                    password2.length > 0 &&
                    password == password2
                  )
                "
                class="btn-login"
                @click="cambiarPassword"
              >
                <span>CAMBIAR</span>
              </button>
            </div>

            <router-link to="/login" class="nav-link"
              ><font-awesome-icon icon="sign-in-alt" /> Volver a
              login</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div id="block_container" class="d-flex justify-content-center contenedor">
      <div class="container">
        <p class="image-logo">
          <img src="../images/logo_transparente.png" width="300px" />
        </p>
        <p class="texto-logo">
          Mendel Brain avanza con el fin de acercar la genética de la
          personalidad a las mentes más curiosas
        </p>
        <p class="texto-pie">
          Made with ♥ by Mendel’s Brain S.L.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CambiarPasswordUrl',
  data() {
    return {
      opcion: 1,
      comprobado: false,
      texto: '',
      password: '',
      password2: ''
    };
  },
  computed: {},
  methods: {
    comprobarToken() {
      this.busy = true;
      axios
        .get(
          'https://api.mendelbrain.com:55010/api/mendel/auth/resetpassword/token/' +
            this.$route.query.token,
          {
            headers: {}
          }
        )
        .then(response => {
          this.comprobado = response.data;
        });
    },
    cambiarPassword() {
      this.busy = true;
      axios
        .put(
          'https://api.mendelbrain.com:55010/api/mendel/auth/resetpassword/password',
          {
            token: this.$route.query.token,
            password: this.password
          },
          {
            headers: {}
          }
        )
        .then(response => {
          this.texto = response.data;
        });
      this.opcion = 2;
      //console.logthis.password);
    }
  },
  mounted() {
    this.comprobarToken();
  }
};
</script>

<style scoped>
.texto-logo {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #ffffff;
  line-height: 1em;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  overflow-wrap: break-word;
  font-weight: 300;
  font-size: 20px;
}
.image-logo {
  text-align: center;
}

.texto-pie {
  text-align: center;
  margin-left: auto;
  margin-top: auto;
  margin-right: auto;
  text-align: center;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
}
label {
  display: block;
  margin-top: 10px;
}
.btn-login {
  border-radius: 16px;
  border-style: solid;
  border-width: medium;
  padding: 12px 35px 12px 35px;
  font-weight: bold;
  color: #8caf36;
  border-color: #8caf36;
  background-color: #ffffff;
}
.btn-login:hover {
  border-radius: 16px;
  border-style: solid;
  border-width: medium;
  padding: 12px 35px 12px 35px;
  font-weight: bold;
  color: #ffffff;
  border-color: #8caf36;
  background-color: #8caf36;
}
.texfield-formulario {
  background-color: #ffffff;
  border-radius: 10px;
}
input::placeholder {
  color: #bbbbbb;
  font-size: 1em;
}
.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}
.card {
  max-width: 800px;
  background-color: #ffffff;
  padding: 20px 30px 20px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 20px;
  -moz-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.profile-img-card {
  width: 80px;
  height: 80px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.nav-link {
  color: #aaaaaa;
}
.contenedor {
  margin: 0px 0px 0px 0px;
  padding: 5px 5px 5px 5px;
  position: relative;
  text-align: center;
}

@media (min-width: 320px) and (max-width: 900px) {
  .contenedor {
    margin: 0px 0px 0px 0px;
    padding: 4px 4px 4px 4px;

    text-align: center;
  }
  .texto-pie {
    font-size: 10px;
    text-align: center;
  }
  .texto-logo {
    font-size: 10px;
  }
}
</style>
